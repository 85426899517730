<template>
  <v-container>
    <v-row>
      <v-col cols=12>
        <v-card elevation="10">
          <v-card-title>Interacciones por tag
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
              items-per-page="5"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            dense
            :headers="headers"
            :items="interactions"
            :search="search"
            :sort-by="['tagId']"
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import {db} from '@/firebase'
import {collection, query, onSnapshot} from 'firebase/firestore'


export default {
  data() {
    return {
      headers: [
        { text: 'Id Tag', value: 'tagId'},
        { text: 'Interacciones', value: 'visits'},
      ],
      search: '',
      interactions: []
    }
  },

  methods: {
    async getInteractions(){
      const q = query(collection(db, "custom/A105/interactions"))
      const unsubscribe = onSnapshot(q, querySnapshot => {
        this.interactions = []
        querySnapshot.forEach(item => {
          const obj = {
            tagId: item.id,
            visits: item.data().interactions
          }
          this.interactions.push(obj)
        })
      })
    }
  },

  created() {
    this.getInteractions()
  },

}
</script>