var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"10"}},[_c('v-card-title',[_vm._v("Interacciones Custom: "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.searchCustom),callback:function ($$v) {_vm.searchCustom=$$v},expression:"searchCustom"}})],1),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.interactionsCustom,"search":_vm.searchCustom,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"items-per-page":5},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.birthday",fn:function(ref){
var item = ref.item;
return [(item.birthday != null)?_c('p',[_vm._v(_vm._s(item.birthday.toDate().toLocaleDateString()))]):_vm._e()]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created.toDate().toLocaleDateString())+" - "+_vm._s(item.created.toDate().toLocaleTimeString())+" ")]}},(_vm.isAdmin)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.deleteItemCustom(item)}}},[_vm._v(" mdi-delete ")])]}}:null],null,true)})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"10"}},[_c('v-card-title',[_vm._v("Interacciones General: "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.interactions,"search":_vm.search,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"items-per-page":5},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.birthday",fn:function(ref){
var item = ref.item;
return [(item.birthday != null)?_c('p',[_vm._v(_vm._s(item.birthday.toDate().toLocaleDateString()))]):_vm._e()]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created.toDate().toLocaleDateString())+" - "+_vm._s(item.created.toDate().toLocaleTimeString())+" ")]}},(_vm.isAdmin)?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}:null],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }