<template>
  <v-container>
    <v-row>
      <v-col cols="12">
          
          <v-data-table
            :headers="headers"
            :items="clients"
            :search="search"
            sort-by="id"
            class="elevation-10"
            dense
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Clientes</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-text-field class="mr-5"
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
                
                <v-dialog
                  v-model="dialog"
                  max-width="500px"
                  persistent
                >
                  <template v-slot:activator="{ on, attrs }">
                    
                    <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Nuevo Cliente
                    </v-btn>
                  </template>
          
                  <v-card>
                    <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.clientId"
                              label="Id"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.clientDesc"
                              label="Descripción"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          @click="close"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn
                          color="primary"
                          @click="save"
                          :disabled="editedItem.clientId == '' || editedItem.clientDesc == ''"
                        >
                          Guardar
                        </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="dialogDelete" max-width="500px" persistent>
                  <v-card>
                    <v-card-title class="text-h5 text-center">¿Quieres eliminar el cliente "{{editedItem.clientDesc}}",<br>se eliminarán todos sus tags?</v-card-title>
                    <v-card-text class="text-center">
                      <v-progress-circular
                        v-if="showLoading == true"
                        indeterminate
                        color="primary"
                        size="70"
                        width="7"  
                      ></v-progress-circular>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="closeDelete">Cancelar</v-btn>
                      <v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

               

              </v-toolbar>
            </template>


            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
                color="primary"
              >
                mdi-pencil
              </v-icon>
              
              <v-icon
                small
                @click="deleteItem(item)"
                color="red"
              >
                mdi-delete
              </v-icon>
          </template>

        </v-data-table>


      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return {
      showLoading: false,
      search: '',
      headers: [
        { text: 'ID cliente', align: 'start', value: 'clientId'},
        { text: 'Descripción', value: 'clientDesc'},
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        clientId: '',
        clientDesc: '',
      },
      defaultItem: {
        clientId: '',
        clientDesc: '', 
      },

    }
  },

  computed: {
    ...mapState(['clients']),
    formTitle () {
      return this.editedIndex === -1 ? 'Nuevo Cliente' : 'Editar Cliente'
    },
  },

  methods: {
    ...mapActions(['getClients','updateClient','deleteClient']),

    editItem (item) {
      this.editedIndex = this.clients.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },


    deleteItem (item) {
      this.editedIndex = this.clients.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm () {
      this.showLoading = true
      await this.deleteClient(this.editedItem)
      //this.clients.splice(this.editedIndex, 1)
      this.closeDelete()
      this.showLoading = false
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async save () {
      await this.updateClient(this.editedItem)
      //this.$store.state.user.clients = this.clients
      /*if (this.editedIndex > -1) {
        //Object.assign(this.desserts[this.editedIndex], this.editedItem)
        this.updateClient(this.editedItem)
      } else {
        //this.desserts.push(this.editedItem)
      }*/
      this.close()
    },
    
  },

  

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.getClients()
  },

}
</script>

<style>

</style>