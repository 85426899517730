<template>
  <v-container>
    <InteractionsAdmin v-if="user.role == 'anuadmin'" />
    <InteractionsLaIsla v-else-if="clientsIds.some(e => laIsla.includes(e)) && user.role == 'user'" />
    <InteractionsSynergy v-else-if="clientsIds.includes('A105') && user.role == 'user'" />
    <InteractionsGeneral v-else/>
  </v-container>
</template>

<script>
import InteractionsAdmin from "@/components/general/InteractionsAdmin.vue";
import InteractionsGeneral from "@/components/general/InteractionsGeneral.vue";
import InteractionsLaIsla from "@/components/laIslaMariscosLapizlazuli/InteractionsLaIsla.vue";
import InteractionsSynergy from "@/components/synergyStudio/InteractionsSynergy.vue";

import { mapState } from 'vuex'

export default {
  name: 'Interactions',
  components: {
    InteractionsAdmin,
    InteractionsGeneral,
    InteractionsLaIsla,
    InteractionsSynergy,
  },

  data() {
    return {
      laIsla: ['A101','A102']
    }
  },

  computed: {
    ...mapState(['user']),
    clientsIds() {
      return this.user.clients.map(e => e.clientId)
    }
  },


}
</script>
