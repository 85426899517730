<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card elevation="10">
          <v-card-title>Interacciones Custom:
            <v-spacer></v-spacer>
              <v-text-field
                v-model="searchCustom"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
          </v-card-title>
          <v-data-table
            dense
            :headers="headers"
            :items="interactionsCustom"
            :search="searchCustom"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :items-per-page="5"
          >
            <template v-slot:[`item.birthday`]="{ item }">
              <p v-if="item.birthday != null">{{item.birthday.toDate().toLocaleDateString()}}</p>
            </template>

            <template v-slot:[`item.created`]="{ item }">
              {{item.created.toDate().toLocaleDateString()}} - {{item.created.toDate().toLocaleTimeString()}}
            </template>

            <template v-slot:[`item.actions`]="{ item }" v-if="isAdmin">
              <v-icon
                small
                @click="deleteItemCustom(item)"
                color="red"
              >
                mdi-delete
              </v-icon>
            </template>

          </v-data-table>
        </v-card>
      </v-col>
    </v-row>



    <v-row>
      <v-col cols="12">
        <v-card elevation="10">
            <v-card-title>Interacciones General:
              <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
            </v-card-title>
            <v-data-table
              dense
              :headers="headers"
              :items="interactions"
              :search="search"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :items-per-page="5"
            >
              <template v-slot:[`item.birthday`]="{ item }">
                <p v-if="item.birthday != null">{{item.birthday.toDate().toLocaleDateString()}}</p>
              </template>

              <template v-slot:[`item.created`]="{ item }">
                {{item.created.toDate().toLocaleDateString()}} - {{item.created.toDate().toLocaleTimeString()}}
              </template>

              <template v-slot:[`item.actions`]="{ item }" v-if="isAdmin">
                <v-icon
                  small
                  @click="deleteItem(item)"
                  color="red"
                >
                  mdi-delete
                </v-icon>
              </template>

            </v-data-table>
          </v-card>
    
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  data() {
    return {
      search: '',
      searchCustom: '',
      sortBy: "created",
      sortDesc: true,
      headers: [
        { text: 'Fecha DD/MM/YYYY', value: 'created', align: 'start'},
        { text: 'Id Cliente', value: 'clientId'},
        { text: 'Nombre Cliente', value: 'clientDesc'},
        { text: 'Fecha Nacimiento DD/MM/YYYY', value: 'birthday'},
        { text: 'Nombre', value: 'name'},
        { text: 'Email', value: 'email'},
        { text: 'Empresa', value: 'company'},
        { text: 'Id Tag', value: 'tagId'},
        { text: 'Descripción', value: 'urlDesc'},
        //{ text: 'URL', value: 'url'},
        //{ text: 'Interacción', value: 'interaction'},
        //{ text: 'LogIn', value: 'provider'},
        { text: 'Acciones', value: 'actions', sortable: false, },
      ],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      
    }
  },
  computed: {
    ...mapState(['interactions','interactionsCustom']),
    ...mapGetters(['isAdmin'])
  },

  methods: {
    ...mapActions(['getInteractions','deleteInteraction','getInteractionsCustom','deleteInteractionCustom']),

    deleteItem (item) {
      this.editedIndex = this.interactions.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.deleteInteraction(this.editedItem)
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    deleteItemCustom (item) {
      this.editedIndex = this.interactions.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.deleteInteractionCustom(this.editedItem)
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

  },

  created() {
    this.getInteractions()
    this.getInteractionsCustom()
  },
}
</script>

<style>

</style>