<template>
  <v-container>
    <!--<v-btn color="primary" class="mt-4" @click="calis()">Calis</v-btn>-->
    <v-row>
      <v-col cols="4" sm="2" md="2">
        <v-select
          v-model="client"
          :items="user.clients"
          label="Cliente"
          dense
          solo
          class="mt-3"
          item-text="clientDesc"
          return-object
        ></v-select>
      </v-col>  
      <v-col cols="4" sm="5" md="3">
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filter.dateStart"
              label="Fecha Inicio (YYYY/MM/DD) - 00:00:00"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filter.dateStart"
            @input="menu1 = false"
            locale="es-mx"
            no-title
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="4" sm="5" md="3">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="filter.dateEnd"
              label="Fecha Final (YYYY/MM/DD) - 23:59:59"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filter.dateEnd"
            @input="menu2 = false"
            locale="es-mx"
            no-title
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="10" sm="10" md="3">
        <v-btn 
        :disabled="!filter.dateStart || !filter.dateEnd"
        color="primary" 
        class="mt-4" 
        @click="getInteractions(filter)">Filtrar Fecha</v-btn>
        <v-btn 
        :disabled="filter.dateStart == null && filter.dateEnd == null"
        color="primary" 
        class="mt-4 ml-2" 
        @click="cleanFilter()">Limpiar</v-btn>
      </v-col>

      <v-col cols="2" sm="2" md="1">
        <v-btn color="primary" class="mt-4" @click="csv()">CSV</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-card elevation="10">
          <v-card-title>Interacciones por tag
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchInteractionsByVisit"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            dense
            :headers="headersInteractionsByVisit"
            :items="countMostVisited"
            :search="searchInteractionsByVisit"
            :sort-desc.sync="sortDesc"
            :sort-by="['interactions']"
            :items-per-page="5"
          >
          </v-data-table>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card elevation="10">
          <v-card-title>Interacciones por usuario
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchVisits"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            dense
            :headers="headersVisits"
            :items="countInteractions"
            :search="searchVisits"
            :sort-by="['interactions']"
            :sort-desc.sync="sortDesc"
            :items-per-page="10"
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card elevation="10">
            <v-card-title>Interacciones totales: <p v-if="interactionsByClient" class="mt-4 ml-2">{{interactionsByClient.length}}</p>
              <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
            </v-card-title>
            <v-data-table
              dense
              :headers="headers"
              :items="interactionsByClient"
              :search="search"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :items-per-page="10"
            >
              <template v-slot:[`item.birthday`]="{ item }">
                <p v-if="item.birthday != null">{{item.birthday.toDate().toLocaleDateString()}}</p>
              </template>

              <template v-slot:[`item.created`]="{ item }">
                {{item.created.toDate().toLocaleDateString()}} - {{item.created.toDate().toLocaleTimeString()}}
              </template>

              <template v-slot:[`item.actions`]="{ item }" v-if="isAdmin">
                <v-icon
                  small
                  @click="deleteItem(item)"
                  color="red"
                >
                  mdi-delete
                </v-icon>
              </template>

            </v-data-table>
          </v-card>
    
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
export default {
  name: 'InteractionsGeneral',
  data() {
    return {
      client: null,
      filter: {
        dateStart: null,
        dateEnd: null
      },
      menu1: false,
      menu2: false,
      search: '',
      searchVisits: '',
      searchInteractionsByVisit: '',
      sortBy: "created",
      sortDesc: true,
      headers: [
        { text: 'Fecha DD/MM/YYYY', value: 'created', align: 'start'},
        { text: 'Id Cliente', value: 'clientId'},
        { text: 'Nombre Cliente', value: 'clientDesc'},
        { text: 'Fecha Nacimiento DD/MM/YYYY', value: 'birthday'},
        { text: 'Nombre', value: 'name'},
        { text: 'Email', value: 'email'},
        { text: 'Empresa', value: 'company'},
        { text: 'Id Tag', value: 'tagId'},
        { text: 'Descripción', value: 'urlDesc'},
        //{ text: 'URL', value: 'url'},
        //{ text: 'Interacción', value: 'interaction'},
        //{ text: 'LogIn', value: 'provider'},
        { text: 'Acciones', value: 'actions', sortable: false, },
      ],
      headersVisits: [
        { text: 'Cliente', value: 'clientDesc'},
        { text: 'Email', value: 'email'},
        { text: 'Interacciones', value: 'interactions'},
      ],
      headersInteractionsByVisit: [
        { text: 'Cliente', value: 'clientDesc'},
        { text: 'Interacción', value: 'desc'},
        { text: 'Interacciones', value: 'interactions'},
      ],
      csvString: [],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
    }
  },

  computed: {
    ...mapState(['interactions', 'user']),
    ...mapGetters(['isAdmin']),

    interactionsByClient() {
      if(this.client){return this.interactions.filter(e => e.clientId == this.client.clientId)}
    },

    countInteractions(){
      if(this.interactionsByClient){
        const visits = []
        const emails = [...new Set(this.interactionsByClient.map(e => e.email))]
        emails.forEach(item => {
          const inteByUserArray = this.interactionsByClient.filter(e => e.email == item)
          const Obj = {
            clientDesc: inteByUserArray[0].clientDesc,
            email: inteByUserArray[0].email,
            interactions: inteByUserArray.length
          }
          visits.push(Obj)
        })
        return visits
      }
    },

    countMostVisited(){
      if(this.interactionsByClient){
        const visits = []
        const descs = [...new Set(this.interactionsByClient.map(e => e.urlDesc))]
        descs.forEach(item => {
          const inteByVisitArray = this.interactionsByClient.filter(e => e.urlDesc == item)
          const Obj = {
            clientDesc: inteByVisitArray[0].clientDesc,
            desc: inteByVisitArray[0].urlDesc,
            interactions: inteByVisitArray.length
          }
          visits.push(Obj)
        })
        return visits
      }
    },

  },

  methods: {
    ...mapActions(['getInteractions','deleteInteraction']),

    cleanFilter(){
      this.filter.dateStart = null
      this.filter.dateEnd = null
      this.getInteractions()
    },

    csv(){
      this.csvString = [
        [
          "Fecha Interaccion",
          "Id Cliente",
          "Nombre Cliente",
          "Fecha de Nacimiento",
          "Nombre",
          "Email",
          "Empresa",
          "Id Tag",
          "Descripcion Tag",
          "Descripcion URL",
          //"URL",
          //"Interaccion",
          //"Login",
        ],
        ...this.interactions.map(item => [
          item.created.toDate(),
          item.clientId,
          item.clientDesc,
          item.birthday.toDate().toLocaleDateString(),
          item.name,
          item.email,
          item.company,
          item.tagId,
          item.desc,
          item.urlDesc,
          //item.url,
          //item.interaction,
          //item.provider
        ])
      ].map(e => e.join(","))
      .join("\n")
      //console.log(this.csvString);

      var hiddenElement = document.createElement('a');  
      hiddenElement.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(this.csvString);  
      hiddenElement.target = '_blank';    
      hiddenElement.download = 'interactions.csv';  
      hiddenElement.click();
    },

    deleteItem (item) {
      this.editedIndex = this.interactions.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.deleteInteraction(this.editedItem)
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    
  },

  created() {
    this.getInteractions()
  },

}
</script>
