<template>
  <v-container>
    <v-row>
      <v-col cols="10">
        <v-card elevation="10">
          <v-card-title>Interacciones por usuario
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchVisits"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            dense
            :headers="headersVisits"
            :items="countInteractions"
            :search="searchVisits"
            :sort-by="['interactions']"
            :sort-desc.sync="sortDesc"
            :items-per-page="5"
          >
            <template v-slot:[`item.birthday`]="{ item }">
              <p v-if="item.birthday != null">{{item.birthday.toDate().toLocaleDateString()}}</p>
            </template>

          </v-data-table>
        </v-card>
      </v-col>
      
      <v-col cols="2" sm="2" md="1">
        <v-btn color="primary" @click="csv()">CSV</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols=12>
        <v-card elevation="10">
          <v-card-title>Interacciones totales: <p class="mt-4 ml-2">{{interactions.length}}</p>
            <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              ></v-text-field>
          </v-card-title>
          <v-data-table
            dense
            :headers="headers"
            :items="interactions"
            :search="search"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :items-per-page="10"
          >
            <template v-slot:[`item.birthday`]="{ item }">
              <p v-if="item.birthday != null">{{item.birthday.toDate().toLocaleDateString()}}</p>
            </template>

            <template v-slot:[`item.created`]="{ item }">
              {{item.created.toDate().toLocaleDateString()}} - {{item.created.toDate().toLocaleTimeString()}}
            </template>

            <!--<template v-slot:[`item.actions`]="{ item }" v-if="isAdmin">
              <v-icon
                small
                @click="deleteItem(item)"
                color="red"
              >
                mdi-delete
              </v-icon>
            </template>-->

          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import {db} from '@/firebase'
import {collection, query, onSnapshot} from 'firebase/firestore'

export default {
  data() {
    return {
      sortBy: "created",
      sortDesc: true,
      search: '',
      searchVisits: '',
      headers: [
        { text: 'Fecha DD/MM/YYYY', value: 'created', align: 'start'},
        { text: 'Id Cliente', value: 'clientId'},
        { text: 'Nombre Cliente', value: 'clientDesc'},
        { text: 'Fecha Nacimiento DD/MM/YYYY', value: 'birthday'},
        { text: 'Nombre', value: 'name'},
        { text: 'Email', value: 'email'},
        { text: 'Id Tag', value: 'tagId'},
        { text: 'Descripción', value: 'urlDesc'},
        //{ text: 'URL', value: 'url'},
        //{ text: 'Interacción', value: 'interaction'},
        //{ text: 'LogIn', value: 'provider'},
        //{ text: 'Acciones', value: 'actions', sortable: false, },
      ],
      headersVisits: [
        { text: 'Nombre', value: 'name'},
        { text: 'Email', value: 'email'},
        { text: 'Fecha Nacimiento DD/MM/YYYY', value: 'birthday'},
        { text: 'Visitas', value: 'interactions'},
      ],
      
      interactions: [],
      csvString: [],
    }
  },

  computed: {
    countInteractions(){
      if(this.interactions){
        const visits = []
        const emails = [...new Set(this.interactions.map(e => e.email))]
        emails.forEach(item => {
          const inteByUserArray = this.interactions.filter(e => e.email == item)
          //console.log(inteByUserArray)
          const Obj = {
            name: inteByUserArray[0].name,
            email: inteByUserArray[0].email,
            birthday: inteByUserArray[0].birthday,
            interactions: inteByUserArray.length
          }
          visits.push(Obj)
        })
        return visits
      }
    },
  },

  methods: {
    async getInteractions(){
      const q = query(collection(db, "custom/A101/interactions"))
      const unsubscribe = onSnapshot(q, querySnapshot => {
        this.interactions = []
        querySnapshot.forEach(item => {
          this.interactions.push(item.data())
        })
      })
    },

    csv(){
      this.csvString = [
        [
          "Nombre",
          "Email",
          "Fecha de Nacimiento",
          "Visitas",
        ],
        ...this.countInteractions.map(item => [
          item.name,
          item.email,
          item.birthday.toDate().toLocaleDateString(),
          item.interactions,
        ])
      ].map(e => e.join(","))
      .join("\n")
      //console.log(this.csvString);

      var hiddenElement = document.createElement('a');  
      hiddenElement.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(this.csvString);  
      hiddenElement.target = '_blank';    
      hiddenElement.download = 'visits.csv';  
      hiddenElement.click();
    },
  },

  created() {
    this.getInteractions()
  },

}
</script>

